import { useState, useEffect } from 'react';
//import axios from 'axios';
import UfosList from '../components/UfosList';
import UfoService from '../services/UfoService.js'
//import { Link } from 'react-router-dom';

// this exports a array named ufos that holds objects
// ..but we want our stuff from server
//import ufos from './ufo-content';

const UfosListPage = () => {
    const [ufos, setUfos] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('a');
    const [searchEntry, setSearchEntry] = useState('');
    const [ufosCount, setUfosCount] = useState(1000);


    useEffect(() => {
        const loadData = async () => {
            ///const response = await axios.get('https://ufos2023fastapi.awscheap.com/ufosightings/1/10');
            //console.log("about to call UfoService.getUfos(" + pageNumber + ", " + perPage + ");")

            const response = (searchTerm === 'a') ? await UfoService.getUfos(pageNumber, perPage) : await UfoService.searchUfos(searchTerm, pageNumber, perPage)
            // NO, this does not change value of pageNumber, as console.log would tell us:
            //setPageNumber(pageNumber + 1);
            //console.log("just called setPageNumber(pageNumber + 1) ..and so hoping pageNumber is 2 but its actually: " + pageNumber)
            console.log(response.data);
            setUfos(response.data);
        }
        loadData();
    }, [pageNumber, perPage]);
    //NO, this line causes: Uncaught Error: Too many re-renders. React limits the number of renders to prevent an infinite loop.
    //setPageNumber(pageNumber + 1);
    useEffect(() => {
        const loadData = async () => {
            //const response = await UfoService.getUfos(pageNumber, perPage);
            //const timeOutId = setTimeout(() => { }, 1500);
            const responseCount = await UfoService.searchUfosCount(searchTerm);
            console.log("responseCount.data:"+responseCount.data);
            setUfosCount(Number(responseCount.data));
            console.log("ufosCount:"+ufosCount);
            // NO, this does not change value of ufosCount, as console.log would tell us:
            //setUfosCount(responseCount.data);
            //setUfosCount(Number(responseCount.data));
            //console.log("typeof(ufosCount):"+typeof(ufosCount));
            const response = await UfoService.searchUfos(searchTerm, pageNumber, perPage);
            console.log("response.data:"+response.data);
            setUfos(response.data);
            //return () => clearTimeout(timeOutId);  
            //return ufosCount;
        }
        //NO countValue is a promise
        //let countValue = loadData();
        //console.log("ufosCount via countValue:"+countValue);
        loadData();
    }, [searchTerm]);

  const searchForUfos = (event) => {
    event.preventDefault();
    setSearchTerm(searchEntry);
    setPageNumber(1);
  };
  let nextButtonDisabled = pageNumber*perPage > ufosCount;
  let backButtonDisabled = pageNumber === 1;
    return (
        <>
        <h1>Ufo Sightings</h1> 
        <div>
            <h3>Search for UFO Sightings</h3>
            <form onSubmit={searchForUfos} style={{display: "inline"}}>
              <input
                type="text"
                name="searchEntry"
                value={searchEntry}
                onChange={(event) => { setSearchEntry(event.target.value)}}
              />
              <button type="submit">Search</button>
            </form>

            {pageNumber === 1 && <button style={{cursor: "not-allowed", background: "grey"}} onClick={() => { return false;} }>Back</button>}
            {pageNumber > 1 && <button onClick={() => setPageNumber(pageNumber - 1)}>Back</button>}
            {pageNumber*perPage >= ufosCount && <button style={{cursor: "not-allowed", background: "grey"}} onClick={() => { return false; } }>Next</button>}
            {pageNumber*perPage < ufosCount && <button onClick={() => setPageNumber(pageNumber + 1)}>Next</button>}




            <label>
              UFOs per page:
              <select value={perPage} onChange={e => setPerPage(Number(e.target.value))} >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
              </select>
            </label>
            <div>showing {(pageNumber-1)*perPage} thru  {pageNumber*perPage} of {ufosCount} search results</div>
        </div>
        <UfosList ufos={ufos} />
        </>
    );
}

export default UfosListPage;
//20230421
//        <UfosList ufos={ufos, searchTerm, pageNumber, perPage} />
//
// these work but cause button to disappear completely
//            <button aria-disabled={backButtonDisabled} onClick={() => setPageNumber(pageNumber - 1)}>Back</button>
//            <button aria-disabled={nextButtonDisabled} onClick={() => setPageNumber(pageNumber + 1)}>Next</button>
//
            // NOT WORKING: trying to get conditional rendering as on line 1779 of news_202303.md
            //{(pageNumber*perPage > ufosCount) ? null : <button onClick={() => setPageNumber(pageNumber + 1)}>Next {perPage} Ufos</button> &nbsp; &nbsp; &nbsp; &nbsp;}


//        <UfosList ufos={ufos} ufoInfo={ufoInfo} />
//
//        <UfosList ufos={ufos} ufoInfo={ufoInfo} />
//
//        <UfosList ufos={ufos} pageNumber={pageNumber} perPage={perPage} />
//
//            <input type="number" value={perPage} onChange={e => setPerPage(Number(e.target.value))} /> UFOs per page
//
// WORKS but causes a URL request every single key press:
//                <input type="text" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />

//
//            <!--
//<input type="text" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
//
//    <form>
//      <input type="text" value={value} onChange={handleChange} />
//      <pre>{value}</pre>
//    </form>
//
//-->
//
//<form onsubmit="doSomething()">
//<input type="text" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
//        <input type="text" placeholder="Email Address" />
//        <input type="Submit" value="Submit Information" />
//</form>
//            <button onClick={() => setSearchTerm()}>Search</button>
//
//    const [searchTerm, setSearchTerm] = useState('drinking');
//            <input type="text" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
