import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import UfosList from '../components/UfosList';
import NotFoundPage from './NotFoundPage';
import ufos from './ufo-content';
//import { Link } from 'react-router-dom';


const UfoPage = () => {
    const [ufo, setUfo] = useState(null);
    const { id } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            console.log('about to call axios.get(Qhttps://ufos2023fastapi.awscheap.com/ufos/Q PLUS'+ id +')');
            const response = await axios.get('https://ufos2023fastapi.awscheap.com/ufos/' + id);
            console.log(response.data);
            setUfo(response.data);
        }

        loadData();
    }, []);

    return (
        <>
        <h1>Ufosighting</h1>
        {ufo && <div id="upvotes-section">
            <b>Date</b>: {ufo[0].reportedat}<br />
            <b>Location</b>: {ufo[0].locationcity}, {ufo[0].locationstate} <br />
            <b>Duration</b>: {ufo[0].duration}<br />
            <b>Shape</b>: {ufo[0].shape}<br />
            <b>Description</b>: {ufo[0].description}<br />
        </div>}
        <button onClick={() => navigate(-1)}>back</button>

        </>
    );
}

export default UfoPage;
//20230421
//            <Link className="article-list-item" to={`/ufosightings/${search_term}/${page_number}/${items_per_page}`}>Back</Link>

//    id              = BigIntegerField(default=datetime.datetime.now().strftime("%Y%m%d%H%M%S"))
//    description     = TextField()
//    duration        = CharField(max_length=255)
//    locationcity    = CharField(max_length=255)
//    locationstate   = CharField(max_length=255)
//    reportedat      = IntegerField()
//    shape           = CharField(max_length=255)
//    sightedatday    = IntegerField()
//    sightedatmonth  = IntegerField()
//    sightedatyear   = IntegerField()
