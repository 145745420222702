import axios from 'axios'
//import { useState, useEffect } from 'react';
//import { useState } from 'react';


//const pageNumber, setPageNumber = useState(1);
//const perPage, setPerPage = useState(10);

const apiClient = axios.create({
  //baseURL: 'http://ufos.awscheap.com:8000',
  //WORKS20250118:
  baseURL: 'https://ufos2023fastapi.awscheap.com',
  //NOT WORKING 20250118:
  //baseURL: 'http://3.21.52.215:3000',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getUfos(pageNumber=1, perPage=2) {
    //return apiClient.get('/ufosightings/1/10')
    //setPageNumber(pageNumber + perPage);
    console.log('about to .get: Q/ufosightings/' + pageNumber + '/' + perPage + 'Q')
    return apiClient.get('/ufosightings/' + pageNumber + '/' + perPage)
  },
  getUfo(id) {
    return apiClient.get('/ufos/' + id)
  },
  searchUfos(searchTerm="green", pageNumber=1, perPage=2) {
    //return apiClient.get('/ufosightings/green')
    console.log('about to .get: Q/ufosightings/' + searchTerm + '/' + pageNumber + '/' + perPage + 'Q')
    if (searchTerm === "") {
        searchTerm = "a"
    }
    return apiClient.get('/ufosightings/' + searchTerm + '/' + pageNumber + '/' + perPage)
  },
  searchUfosCount(searchTerm="green") {
    //return apiClient.get('/ufosightings/green')
    if (searchTerm === "") {
        searchTerm = "a"
    }
    console.log('about to .get: Q/ufosightingscount/' + searchTerm + 'Q')
    return apiClient.get('/ufosightingscount/' + searchTerm)
  }
}
